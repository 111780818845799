const DONE_STATE = 4;

const checkLoginRedirect = request => {
  const { responseURL } = request;
  const matchLogin = /\/account\/signin/s;
  const match = matchLogin.exec(responseURL);

  if (match) {
    if (match.index >= 0) {
      window.location.replace(
        `${window.origin}/account/signin?returnurl=${window.location.pathname}`
      );
    }
  }
};

const xhr = obj =>
  new Promise((resolve, reject) => {
    if (obj.signal && obj.signal.aborted) {
      reject(new Error('Abort'));
    }

    const request = new XMLHttpRequest();
    const abort = () => request.abort();

    request.open(obj.method || 'GET', obj.url);
    if (obj.headers) {
      Object.keys(obj.headers).forEach(key =>
        request.setRequestHeader(key, obj.headers[key])
      );
    }
    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        checkLoginRedirect(request);
        resolve(request.response ? JSON.parse(request.response) : null);
      } else if (request.status === 401) {
        // force page reload
        window.location.reload();
      } else {
        reject(new Error(request.response));
      }
    };

    if (obj.signal) {
      obj.signal.addEventListener('abort', abort);

      xhr.onreadystatechange = () => {
        if (xhr.readyState === DONE_STATE) {
          obj.signal.removeEventListener('abort', abort);
        }
      };
    }

    request.onabort = () => reject(new Error('Abort'));
    request.onerror = () => reject(new Error('Request Error'));
    request.send(JSON.stringify(obj.data));
  });

export default xhr;
