import React, { lazy } from 'react';
import Dashboard from '@mui/icons-material/Dashboard';
import Search from '@mui/icons-material/Search';
import People from '@mui/icons-material/People';
import AssignmentInd from '@mui/icons-material/AssignmentInd';
import Language from '@mui/icons-material/Language';
import ContractorIcon from '@mui/icons-material/WorkOutline';
import WillCallIcon from '@mui/icons-material/PlaylistAddCheck';
import DeckIcon from '@mui/icons-material/Deck';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';

const CreateUserAlertButton = lazy(() =>
  import(
    /* webpackChunkName: "createUserAlertButton" */ '../Alerts/CreateUserAlertButton'
  )
);
const ReportButton = lazy(() =>
  import(/* webpackChunkName: "reportButton" */ '../Reports/ReportButton')
);
const VisitorButton = lazy(() =>
  import(/* webpackChunkName: "visitorButton" */ '../Visitor/VisitorButton')
);

const views = [
  { active: false, name: 'dashboard', href: '/', icon: <Dashboard /> },
  { active: false, name: 'search', href: '/search', icon: <Search /> },
  { active: false, name: 'groups', href: '/group', icon: <People /> },
  {
    active: false,
    name: 'Visitor',
    href: null,
    type: 'visitor',
    icon: <VisitorButton />,
  },
  {
    active: false,
    name: 'Will Call',
    href: '/willCall',
    type: 'willCall',
    icon: <WillCallIcon />,
  },
  {
    active: false,
    name: 'contractor registration',
    href: '/contractor',
    type: 'contractor',
    icon: <ContractorIcon />,
  },
  {
    active: false,
    name: 'leads',
    href: '/leads',
    type: 'leads',
    icon: <AssignmentInd />,
  },
  {
    active: false,
    name: 'create User Alert',
    href: null,
    icon: <CreateUserAlertButton />,
  },
  { active: false, name: 'reports', href: null, icon: <ReportButton /> },
  {
    active: false,
    name: 'admin & organization',
    href: '/admin',
    type: 'admin',
    icon: <Language />,
  },
  {
    active: false,
    name: 'Booth Leasing',
    href: '/boothleasing',
    type: 'boothLeasing',
    icon: <DeckIcon />,
  },
  {
    active: true,
    name: 'Fastpass Kiosk',
    href: '/fastpass',
    type: 'fastpass',
    icon: <OfflineBoltIcon />,
  },
];

export default views;
