import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import translateTemplate from '../../lib/templating';
import { BadgeHtmlContainer } from '../StyledComponents/Div';
import parse from 'html-react-parser';

class BadgePreview extends Component {
  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
  }

  componentDidMount() {
    const { badgeTemplate } = this.props;
    if (badgeTemplate && badgeTemplate.includes('qrcode')) {
      this.handleQRCode();
    }
  }

  componentDidUpdate(prevProps) {
    const { badgeTemplate } = this.props;
    if (prevProps.badgeTemplate !== badgeTemplate) {
      if (badgeTemplate && badgeTemplate.includes('qrcode')) {
        this.handleQRCode();
      }
    }
  }

  handleQRCode = () => {
    // Handle QrCode placement
    const qrCodePlaceholder =
      this.containerRef.current.getElementsByClassName('qrcode-placeholder');
    const qrCode = this.containerRef.current.getElementsByClassName('qrcode');

    if (qrCodePlaceholder.length > 0 && qrCode.length > 0) {
      // replace
      const parentDiv = qrCodePlaceholder[0].parentNode;
      const barcode = qrCode[0];
      const placeholder = qrCodePlaceholder[0];
      parentDiv.replaceChild(barcode, placeholder);
    }
  };

  formatBadge = () => {
    const {
      badgeTemplate,
      badgeData: propBadgeData,
      couponHtml,
      customBadgeData,
    } = this.props;

    if (badgeTemplate && propBadgeData && couponHtml) {
      let source = badgeTemplate;

      // concat possible coupon html
      couponHtml.forEach((x) => {
        source = source.concat('<div class="coupon">', x, '</div>');
      });

      // populate template with data
      const badgeData = {
        marketName: propBadgeData.market ? propBadgeData.market.marketName : '',
        marketCode: propBadgeData.market ? propBadgeData.market.marketCode : '',
        companyName:
          propBadgeData.printedBadgeCompanyName ||
          (propBadgeData.companyAssociation &&
            propBadgeData.companyAssociation.company.companyName) ||
          (propBadgeData.hostCompanyAssociation &&
            propBadgeData.hostCompanyAssociation.company.companyName) ||
          '',
        postalCode:
          propBadgeData.companyAssociation &&
          propBadgeData.companyAssociation.company.primaryAddress
            ? propBadgeData.companyAssociation.company.primaryAddress.postalCode
            : '',
        stateAbbr:
          propBadgeData.companyAssociation &&
          propBadgeData.companyAssociation.company.primaryAddress &&
          propBadgeData.companyAssociation.company.primaryAddress.state
            ? propBadgeData.companyAssociation.company.primaryAddress.state
                .stateAbbr
            : '',
        stateName:
          propBadgeData.companyAssociation &&
          propBadgeData.companyAssociation.company.primaryAddress &&
          propBadgeData.companyAssociation.company.primaryAddress.state
            ? propBadgeData.companyAssociation.company.primaryAddress.state
                .stateName
            : '',
        city:
          propBadgeData.companyAssociation &&
          propBadgeData.companyAssociation.company.primaryAddress
            ? propBadgeData.companyAssociation.company.primaryAddress.city
            : '',
        street1:
          propBadgeData.companyAssociation &&
          propBadgeData.companyAssociation.company.primaryAddress
            ? propBadgeData.companyAssociation.company.primaryAddress.street1
            : '',
        street2:
          propBadgeData.companyAssociation &&
          propBadgeData.companyAssociation.company.primaryAddress
            ? propBadgeData.companyAssociation.company.primaryAddress.street2
            : '',
        internationalAddress:
          propBadgeData.companyAssociation &&
          propBadgeData.companyAssociation.company.primaryAddress
            ? propBadgeData.companyAssociation.company.primaryAddress
                .internationalAddress
            : '',
        country:
          propBadgeData.companyAssociation &&
          propBadgeData.companyAssociation.company.primaryAddress
            ? propBadgeData.companyAssociation.company.primaryAddress.country
                .countryName
            : '',
        firstName:
          propBadgeData.printedBadgePersonName ||
          (propBadgeData.companyAssociation &&
            propBadgeData.companyAssociation.person.firstName) ||
          (propBadgeData.person && propBadgeData.person.firstName) ||
          '',
        lastName:
          (propBadgeData.companyAssociation &&
            propBadgeData.companyAssociation.person.lastName) ||
          (propBadgeData.person && propBadgeData.person.lastName) ||
          '',
        fullName:
          (propBadgeData.companyAssociation &&
            propBadgeData.companyAssociation.person.fullName) ||
          (propBadgeData.person && propBadgeData.person.fullName) ||
          '',
        hostFirstName: propBadgeData.hostCompanyAssociation
          ? propBadgeData.hostCompanyAssociation.person.firstName
          : '',
        hostLastName: propBadgeData.hostCompanyAssociation
          ? propBadgeData.hostCompanyAssociation.person.lastName
          : '',
        associationType:
          (propBadgeData.companyAssociation &&
            propBadgeData.companyAssociation.associationType
              .associationTypeName) ||
          (propBadgeData.associationType &&
            propBadgeData.associationType.associationTypeName) ||
          '',
        attendanceType: propBadgeData.companyAssociation
          ? propBadgeData.companyAssociation.associationType.attendanceType
              .attendanceTypeName
          : '',
        qrcode: '<span class="qrcode-placeholder">qrcode placeholder</span>',
        businessType:
          propBadgeData.companyAssociation &&
          propBadgeData.companyAssociation.company &&
          propBadgeData.companyAssociation.company.businessType &&
          propBadgeData.companyAssociation.company.businessType
            .businessTypeName,
        showroom:
          propBadgeData.location ||
          (propBadgeData.companyAssociation &&
            propBadgeData.companyAssociation.company &&
            propBadgeData.companyAssociation.company.primaryShowroom) ||
          '',
        allShowrooms:
          propBadgeData.location ||
          (propBadgeData.companyAssociation &&
            propBadgeData.companyAssociation.company &&
            propBadgeData.companyAssociation.company.allShowrooms) ||
          '',
        currentDate: moment().format('MM/DD/YYYY'),
        tag:
          (propBadgeData.tags &&
            propBadgeData.tags.map((x) => x.tagName).join(',')) ||
          '',
        personalTitle:
          (propBadgeData.companyAssociation &&
            propBadgeData.companyAssociation.person &&
            propBadgeData.companyAssociation.person.personalTitle &&
            propBadgeData.companyAssociation.person.personalTitle
              .personalTitleName) ||
          '',
      };

      return translateTemplate(source, badgeData);
    }
    if (badgeTemplate && customBadgeData) {
      return translateTemplate(badgeTemplate, customBadgeData);
    }
    if (couponHtml?.length > 0) {
      return couponHtml.map((x) => `<div class="coupon">${x}</div>`).join(' ');
    }
    return null;
  };

  formatQRCode = () => {
    const {
      badgeData: {
        companyAssociation: data,
        person: guest,
        hostCompanyAssociation: host,
        market,
      },
    } = this.props;
    const arr = [];
    const person = data ? data.person : guest;
    const company = (data && data.company) || (host && host.company);
    const address = company.primaryAddress;

    arr.push(company.companyId);
    arr.push(person.firstName);
    arr.push(person.lastName);
    arr.push(company.businessType ? company.businessType.businessTypeName : '');
    arr.push(company.companyName);
    arr.push(address ? address.street1 : '');
    arr.push(address ? address.city : '');
    arr.push(address && address.state ? address.state.stateAbbr : '');
    arr.push(address ? address.postalCode : '');
    arr.push(address ? address.country.countryName : '');
    arr.push(
      company.primaryPhoneNumber ? company.primaryPhoneNumber.phoneNumber : ''
    );
    arr.push(person.primaryEmail ? person.primaryEmail.emailAddress : '');
    arr.push(data ? data.companyAssociationId : host.companyAssociationId);
    arr.push(market ? market.marketId : '');

    return arr.join('^');
  };

  render() {
    const { badgeTemplate } = this.props;
    const showQRCode = badgeTemplate && badgeTemplate.includes('qrcode');
    const badgeText = this.formatBadge();
    return (
      <BadgeHtmlContainer ref={this.containerRef}>
        {showQRCode && (
          <div>
            <div className="qrcode">
              <QRCode value={this.formatQRCode()} size={100} />
            </div>
          </div>
        )}
        {badgeText && parse(badgeText)}
      </BadgeHtmlContainer>
    );
  }
}

export default BadgePreview;

BadgePreview.propTypes = {
  badgeTemplate: PropTypes.string,
  badgeData: PropTypes.shape(),
  customBadgeData: PropTypes.shape(),
  couponHtml: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
BadgePreview.defaultProps = {
  badgeTemplate: '',
  badgeData: null,
  customBadgeData: null,
};
